import React, { useEffect, useState } from "react";
import NavHyperLink from "../../../components/NavHyperLink";
import { Button, Table, Modal, message, Pagination } from "antd";
import type { ColumnsType } from "antd/es/table";
import { DatasetTemplate } from "../../../types/DatasetTemplate";
import {
  getAllDatasetTemplateListApi,
  deleteDatasetTemplateApi,
} from "../../../api/DatasetTemplateApi";
import { useNavigate } from "react-router-dom";

const DatasetTemplatePage = () => {
  const navigate = useNavigate();
  const [datalist, setDatalist] = useState<{
    data: DatasetTemplate[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
  });
  const handlePageChange = (page: number) =>
    setFilter((data) => ({ ...data, page: page }));

  useEffect(() => {
    getAllDatasetTemplateListApi({
      page: filter.page,
      page_size: filter.page_size,
    }).then((res) => {
      if (res.success) {
        setDatalist({
          data: res.data,
          total: res.pagination?.count || 0,
        });
      }
    });
  }, [filter]);

  const handleEdit = (id?: number) => {
    if (!!id) {
      navigate(`/system_manage/templates/dataset/edit/${id}`);
    }
  };

  const handleDelete = (id?: number) => {
    if (!!id) {
      Modal.confirm({
        title: "删除数据集模版",
        content: "确认删除数据集模版？一经删除不可恢复",
        centered: true,
        onOk() {
          deleteDatasetTemplateApi({ id: Number(id) }).then((res) => {
            if (res.success) {
              message.success("删除数据集模版成功");
              setFilter((data) => ({ ...data }));
            } else {
              message.error("删除数据集模版失败");
            }
          });
        },
      });
    }
  };

  const handleCreate = (id?: number) => {
    navigate(`/system_manage/templates/dataset/create${!!id ? `/${id}` : ""}`);
  };

  const columns: ColumnsType<DatasetTemplate> = [
    {
      key: "id",
      title: "ID",
      render: (d: DatasetTemplate) => d.id,
    },
    {
      key: "title",
      title: "名称",
      render: (d: DatasetTemplate) => d.manifest?.title?.zh,
    },
    {
      key: "desc",
      title: "描述",
      render: (d: DatasetTemplate) => d.manifest?.desc?.zh,
    },
    {
      key: "type",
      title: "类型",
      render: (d: DatasetTemplate) =>
        d.template_type === "PRIVATE" ? "私有" : "全局",
    },
    {
      key: "active",
      title: "是否激活",
      render: (d: DatasetTemplate) => (!!d.active ? "✅" : "❌"),
    },
    {
      key: "actions",
      width: 100,
      title: "操作",
      render: (d: DatasetTemplate) => (
        <div style={{ display: "flex" }}>
          <Button size="small" type="link" onClick={() => handleEdit(d.id)}>
            编辑
          </Button>
          <Button size="small" type="link" onClick={() => handleCreate(d.id)}>
            复制
          </Button>
          <Button
            size="small"
            type="link"
            danger
            onClick={() => handleDelete(d.id)}
          >
            删除
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <NavHyperLink items={[{ title: "数据集模版" }]} />
        <Button type="primary" onClick={() => handleCreate()}>
          + 新增模版
        </Button>
      </div>
      <div style={{ marginTop: "1.5em" }}>
        <Table
          className="common-table"
          rowKey={(r) => r.id || ""}
          scroll={{ x: 700, scrollToFirstRowOnChange: true }}
          columns={columns}
          dataSource={datalist.data}
          pagination={false}
        />
        <Pagination
          showQuickJumper
          hideOnSinglePage={!datalist.data?.length}
          showTotal={(total) => `共${total}条`}
          current={filter.page}
          pageSize={filter.page_size}
          total={datalist.total}
          onChange={handlePageChange}
          style={{ textAlign: "right", marginTop: "1em" }}
        />
      </div>
    </>
  );
};

export default DatasetTemplatePage;
