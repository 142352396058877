import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  Table,
  Tabs,
  TabsProps,
  Pagination,
  Typography,
  Form,
  Select,
  Space,
  DatePicker,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { DatasetVersion } from "../../types/Dataset";
import { UnorderedListOutlined } from "@ant-design/icons";
import { getPipelineRunsApi } from "../../api/IntegrationsApis";
import { getHistoryLogListApi, getUserListApi } from "../../api";
import { PipelineRun } from "../../types/Integrations";
import { LogRecord } from "../../types/LogRecord";
import { User } from "../../types/User";
import { Workspace } from "../../types/Workspace";
import {
  findLastNonPendingStatus,
  getStatusName,
  makeGetStatus,
} from "../../utils/PipelineUtils";
import viewUtils from "../../utils/viewUtils";
import { getDatasetVersionListApi } from "../../api/DatasetApi";

const DatasetPushRecord = (props: { version?: DatasetVersion }) => {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);

  const getStatus = makeGetStatus(t);

  const columns = [
    {
      title: t("dataset.action_record.version"),
      dataIndex: "version",
      key: "version",
    },
    {
      title: t("dataset.action_record.operator"),
      dataIndex: "operator",
      key: "operator",
    },
    {
      title: t("dataset.action_record.start_time"),
      dataIndex: "startTime",
      key: "startTime",
    },
    {
      title: t("dataset.action_record.end_time"),
      dataIndex: "endTime",
      key: "endTime",
    },
    {
      title: t("dataset.action_record.status"),
      dataIndex: "status",
      key: "status",
    },
  ];

  useEffect(() => {
    if (!!props.version?.id) {
      getPipelineRunsApi({ versionId: props.version?.id! })
        .then((response) => {
          const processedData = response.data.map((run: PipelineRun) => {
            const lastStatus = findLastNonPendingStatus(run.status).status;
            return {
              version: run.version.name,
              operator: run.operator?.name || "",
              startTime: run.start_time
                ? viewUtils.prettifyDatatime(run.start_time)
                : "",
              endTime: run.end_time
                ? viewUtils.prettifyDatatime(run.end_time)
                : "",
              status: lastStatus
                ? `${getStatusName(lastStatus, i18n.language)}${
                    lastStatus.status ? "(" + getStatus(lastStatus) + ")" : ""
                  }`
                : t("dataset.task_status.pending"),
            };
          });
          setTableData(processedData);
        })
        .catch((error) => {
          console.error("Error fetching pipeline runs:", error);
        });
    }
  }, [props.version]);

  return (
    <Table
      size={"small"}
      dataSource={tableData}
      columns={columns}
      pagination={{ pageSize: 10 }}
    />
  );
};

const DatasetActionRecord = connect(
  (store: any) => ({
    currentWorkspace: store.account.currentWorkspace,
  }),
  {}
)((props: { version?: DatasetVersion; currentWorkspace?: Workspace }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [users, setUsers] = useState<User[]>([]);
  const [versions, setVersions] = useState<DatasetVersion[]>([]);
  const [logs, setLogs] = useState<{ data: LogRecord[]; total: number }>({
    data: [],
    total: 0,
  });
  const [filter, setFilter] = useState({
    page: 1,
    page_size: 10,
    version: undefined,
    action: undefined,
    operator: undefined,
    start_time: undefined,
    end_time: undefined,
  });
  const handlePageChange = (page: number, page_size: number) => {
    setFilter((data) => ({ ...data, page: page, page_size: page_size }));
  };

  useEffect(() => {
    if (props.currentWorkspace?.id) {
      getUserListApi({ workspace: Number(props.currentWorkspace?.id) }).then(
        (res) => {
          if (res.success) {
            setUsers(res.data);
          }
        }
      );
      getDatasetVersionListApi({
        page_size: 10,
        dataset: props.version?.dataset,
        ordering: "-created_at",
      }).then((res) => {
        if (res.success) {
          setVersions(res.data);
        }
      });
    }
  }, [props]);

  useEffect(() => {
    if (!props.version) {
      return;
    }
    getHistoryLogListApi({
      dataset_id: props.version.dataset,
      version_id: filter.version,
      ...filter,
    })
      .then((res) => {
        if (res.success) {
          setLogs({
            data: res.data,
            total: res.pagination?.count || 0,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching logs:", error);
      });
  }, [props.version, filter]);

  const renderDetail = (log: LogRecord) => {
    switch (log.action) {
      case "LOCK_TASK":
        return t("dataset.action_record.detail_info.lock_task", {
          table: log.task?.table?.name || " ",
          user: viewUtils.prettifyUsername(log.task?.assignee_user?.name),
        });
      case "UNLOCK_TASK":
        return t("dataset.action_record.detail_info.unlock_task", {
          table: log.task?.table?.name || " ",
          user: viewUtils.prettifyUsername(log.task?.assignee_user?.name),
        });
      case "LOCK_VERSION":
        return t("dataset.action_record.detail_info.lock_version");
      case "UNLOCK_VERSION":
        return t("dataset.action_record.detail_info.unlock_version");
      case "CHANGE_DATA":
        let texts = [];
        if (!!log.detail?.delete_count) {
          texts.push(
            t("dataset.action_record.detail_info.change_data.delete", {
              table: log.task?.table?.name || " ",
              user: viewUtils.prettifyUsername(log.task?.assignee_user?.name),
              count: log.detail?.delete_count,
            })
          );
        }
        if (!!log.detail?.add_count) {
          texts.push(
            t("dataset.action_record.detail_info.change_data.add", {
              table: log.task?.table?.name || " ",
              user: viewUtils.prettifyUsername(log.task?.assignee_user?.name),
              count: log.detail?.add_count,
            })
          );
        }
        if (!!log.detail?.change_data?.length) {
          let changeTextArray: string[] = [];
          const fields = (log.task?.table?.meta?.fields || []).reduce(
            (ret, item) => {
              ret[item.identifier || ""] = item;
              return ret;
            },
            {} as any
          );
          for (let i = 0, len = log.detail?.change_data.length; i < len; i++) {
            let field = fields[log.detail?.change_data[i].field];
            if (!!field) {
              changeTextArray.push(
                t("dataset.action_record.detail_info.change_data.change_cell", {
                  field: field?.name || "",
                  origin: log.detail?.change_data[i].origin_value || "",
                  new: log.detail?.change_data[i].new_value || " ",
                })
              );
            }
          }
          if (changeTextArray.length > 0) {
            texts.push(
              `${t("dataset.action_record.detail_info.change_data.change", {
                table: log.task?.table?.name || " ",
                user: viewUtils.prettifyUsername(log.task?.assignee_user?.name),
              })} : ${changeTextArray.join("; ")}`
            );
          }
        }
        return texts.join("\n");
      default:
        return "";
    }
  };

  const columns: ColumnsType<LogRecord> = [
    {
      title: t("dataset.action_record.version"),
      key: "version",
      render: (r) => r.version.name,
    },
    {
      title: t("dataset.action_record.action"),
      key: "action",
      render: (r) => t(`dataset.action_record.actions.${r.action}`),
    },
    {
      title: t("dataset.action_record.detail"),
      width: "45%",
      key: "detail",
      render: (r) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            tooltip: {
              placement: "bottomLeft",
              overlayStyle: {
                whiteSpace: "pre-line",
                maxWidth: "min(100vw, 400px)",
              },
            },
          }}
          style={{ marginBottom: 0, whiteSpace: "pre-line" }}
        >
          {renderDetail(r)}
        </Typography.Paragraph>
      ),
    },
    {
      title: t("dataset.action_record.operator"),
      key: "operator",
      render: (r) => viewUtils.prettifyUsername(r.operator?.name),
    },
    {
      title: t("dataset.action_record.start_time"),
      key: "created_at",
      render: (r) => viewUtils.prettifyDatatime(r.created_at),
    },
  ];

  const handleSearch = () => {
    const search = form.getFieldsValue();
    const date = search.date || [];
    setFilter((data) => ({
      ...data,
      page: 1,
      version: search.version || undefined,
      action: search.action || undefined,
      operator: search.operator || undefined,
      start_time: !!date[0] ? date[0].format("YYYY-MM-DD") : undefined,
      end_time: !!date[1] ? date[1].format("YYYY-MM-DD") : undefined,
    }));
  };

  const handleResetSearch = () => {
    form.resetFields();
    setFilter((data) => ({
      ...data,
      page: 1,
      version: undefined,
      action: undefined,
      operator: undefined,
      start_time: undefined,
      end_time: undefined,
    }));
  };

  return (
    <>
      <Form form={form} name="log search form">
        <Space
          style={{ display: "flex", flexWrap: "wrap", marginBottom: "1em" }}
        >
          <Form.Item name="version" noStyle>
            <Select
              allowClear
              placeholder={t("dataset.action_record.version")}
              style={{ width: "8em" }}
              options={versions.map((version) => ({
                value: version.id,
                label: version.name,
              }))}
            />
          </Form.Item>
          <Form.Item name="action" noStyle>
            <Select
              allowClear
              placeholder={t("dataset.action_record.action")}
              style={{ width: "8em" }}
              options={[
                "LOCK_TASK",
                "UNLOCK_TASK",
                "LOCK_VERSION",
                "UNLOCK_VERSION",
                "CHANGE_DATA",
              ].map((key) => ({
                value: key,
                label: t(`dataset.action_record.actions.${key}`),
              }))}
            />
          </Form.Item>
          <Form.Item name="operator" noStyle>
            <Select
              allowClear
              placeholder={t("dataset.action_record.operator")}
              style={{ width: "14em" }}
              options={users.map((user) => ({
                value: user.username,
                label: `${viewUtils.prettifyUsername(user.name)}(${
                  user.username
                })`,
              }))}
            />
          </Form.Item>
          <Form.Item name="date" noStyle>
            <DatePicker.RangePicker allowEmpty={[true, true]} />
          </Form.Item>
          <Button type="primary" onClick={handleSearch}>
            {t("common.search")}
          </Button>
          <Button onClick={handleResetSearch}>{t("common.reset")}</Button>
        </Space>
      </Form>
      <Table
        className="common-table"
        size={"small"}
        rowKey={(r) => r?.id || ""}
        scroll={{ x: 500, scrollToFirstRowOnChange: true }}
        columns={columns}
        dataSource={logs.data}
        pagination={false}
      />
      <Pagination
        size={"small"}
        hideOnSinglePage={!logs.data?.length}
        showQuickJumper
        showTotal={(total) => t("common.total", { count: total })}
        current={filter.page}
        pageSize={filter.page_size}
        total={logs.total}
        onChange={handlePageChange}
        style={{ textAlign: "right", margin: "1em 0" }}
      />
    </>
  );
});

const DatasetActionHistory = (props: { version?: DatasetVersion }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  return (
    <>
      <Modal
        centered
        width={1000}
        destroyOnClose={true}
        title={t("dataset.action_record.title")}
        open={!!show}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <Tabs
          defaultActiveKey="all"
          items={
            [
              {
                key: "actions",
                label: t("dataset.action_record.action_record"),
                children: <DatasetActionRecord version={props.version} />,
              },
              {
                key: "push_actions",
                label: t("dataset.action_record.push_record"),
                children: <DatasetPushRecord version={props.version} />,
              },
            ] as TabsProps["items"]
          }
        />
      </Modal>
      <div className="action-item">
        <Button
          icon={
            <UnorderedListOutlined
              style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
            />
          }
          onClick={() => setShow(true)}
        >
          {t("dataset.action_record.title")}
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  currentWorkspace: store.account.currentWorkspace,
});

export default connect(mapStateToProps, {})(DatasetActionHistory);
