// @ts-nocheck
type BMapLoaderfunc = (options: BMapLoaderOptions) => Promise<void>;
interface BMapLoaderOptions {
  key: string;
}
const version = "1.0";

enum LoadStatus {
  notLoad,
  loading,
  success,
  failed,
}
const STATUS = {
  MAP: LoadStatus.notLoad,
};

const delay = (second: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, second);
  });
};

const BMapLoader: BMapLoaderfunc = (options) =>
  new Promise(async (resolve, reject) => {
    while (STATUS.MAP === LoadStatus.loading) {
      await delay(1000);
    }
    let { key } = options;
    if (!key) {
      reject("请填写key");
      return;
    }

    if (STATUS.MAP !== LoadStatus.success) {
      STATUS.MAP = LoadStatus.loading;
      const parentNode = document.body || document.head;
      window.___onBaiduAPILoaded = async () => {
        delete window.___onBaiduAPILoaded;
        await delay(1000);
        if (!window.BMapGL || Object.keys(window.BMapGL).length < 1) {
          delete window.BMapGL;
          document
            .querySelector('scrtpt[src*="http://api.map.baidu.com/getscript"]')
            ?.remove();
          document
            .querySelector('scrtpt[src*="//api.map.baidu.com/api?"]')
            ?.remove();
          STATUS.MAP = LoadStatus.failed;
          reject("加载失败");
        } else {
          STATUS.MAP = LoadStatus.success;
          resolve();
        }
      };

      const script = document.createElement("script");
      script.setAttribute("id", "baidu-map");
      script.type = "text/javascript";
      script.src = `//api.map.baidu.com/api?type=webgl&v=${version}&ak=${key}&callback=___onBaiduAPILoaded`;
      script.onerror = (e) => {
        STATUS.MAP = LoadStatus.failed;
        reject(e);
      };
      parentNode.appendChild(script);
    } else {
      resolve();
    }
  });
export default BMapLoader;
