import React, { useEffect, useState, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Table,
  Typography,
  Tooltip,
  Modal,
  message,
  Form,
  Checkbox,
  Image,
  Dropdown,
  Input,
} from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { ColumnsType } from "antd/es/table";
import type { SelectProps } from "antd";
import { Workspace, Membership, Role } from "../../types/Workspace";
import type { User } from "../../types/User";
import {
  ExclamationCircleOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
} from "@ant-design/icons";
import viewUtils from "../../utils/viewUtils";
import { resetWorkspaceList, updateWorkspace } from "../../store/account";
import {
  getUserListApi,
  uploadBatchAddUsersFileApi,
  downloadBatchAddUsersFileApi,
  getWorkspaceApi,
} from "../../api";
import usePermission from "../../utils/usePermission";
import { getConfig } from "../../config/config";
import Dragger from "antd/es/upload/Dragger";
import Upload, { RcFile } from "antd/es/upload";
import Add from "../../common/images/add.png";
import Excel from "../../common/images/excel.png";
import Failure from "../../common/images/failure.png";
import Success from "../../common/images/success.png";
import DeleteNormal from "../../common/images/delete_normal.png";
import DeleteHover from "../../common/images/delete_hover.png";
import { DefaultOptionType } from "antd/es/select";
import Compact from "antd/es/space/Compact";

const config = getConfig();

const MembershipManagePage = (props: {
  user: User;
  currentWorkspace: Workspace;
  updateWorkspace: (data: any) => void;
  resetWorkspaceList: (data: any) => void;
}) => {
  const { t, i18n } = useTranslation();
  const permission = usePermission();
  const columns: ColumnsType<Membership> = [
    {
      title: t("workspace.account"),
      key: "username",
      render: (r) => r.invitee_account ?? r.user.username,
    },
    {
      title: t("workspace.username"),
      key: "name",
      render: (r) => r.invitee_account ?? r.user.name,
      width: 200,
    },
    {
      title: t("workspace.created_at"),
      key: "created_at",
      render: (r) => viewUtils.utcToLocal(r.created_at),
    },
    {
      title: t("workspace.role"),
      key: "role",
      render: (r) => t(`workspace.${r.role.toLowerCase()}`),
    },
    {
      title: t("workspace.created_by_name"),
      key: "created_by_name",
      render: (r) =>
        r?.role === Role.OWNER ? (
          <div style={{ width: "100%", textAlign: "center" }}>-</div>
        ) : (
          <Tooltip title={r?.created_by?.username ?? r.operator?.username}>
            <div>{r?.created_by?.name ?? r.operator?.name}</div>
          </Tooltip>
        ),
    },
    {
      title: t("workspace.logined.title"),
      key: "logined",
      render: (r) =>
        r.invitee_account === undefined
          ? t("workspace.logined.active")
          : t("workspace.logined.inactive"),
    },
    {
      title: t("common.actions"),
      key: "actions",
      render: (r) => (
        <div>
          {permission.isAdmin && r.role !== Role.OWNER && (
            <>
              <Button size="small" type="link" onClick={() => handleEdit(r)}>
                {t("common.edit")}
              </Button>
              <Button
                size="small"
                type="link"
                onClick={() => handleDeleteMember(r)}
              >
                {t("common.remove")}
              </Button>
            </>
          )}
        </div>
      ),
    },
  ];
  const [membershipModalItem, setMembershipModalItem] = useState(
    {} as {
      workspace?: Workspace;
      membership?: Membership;
    }
  );
  const [batchMembershipModalItem, setBatchMembershipModalItem] = useState(
    {} as {
      workspace?: Workspace;
    }
  );
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace>();

  useEffect(() => {
    resetWorkspace();
  }, [props.currentWorkspace]);

  const BatchMembershipModal = () => {
    enum Tab {
      INVITATION,
      BATCH_ADD,
    }
    const [activeTab, setActiveTab] = useState(Tab.INVITATION);
    const selectedButtonStyle = {
      flex: 1,
      height: "100%",
      borderRadius: 4,
      color: "#2A61FF",
      background: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 600,
    };
    const unselectedButtonStyle = {
      flex: 1,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 400,
    };

    const handleChangeTab = (tab: Tab) => {
      setActiveTab(tab);
    };

    if (!batchMembershipModalItem?.workspace) {
      return null;
    }

    return (
      <Modal
        open={!!batchMembershipModalItem.workspace}
        title={t("workspace.actions.invite_member")}
        onCancel={() => {
          setBatchMembershipModalItem({});
        }}
        footer={null}
        centered={true}
      >
        <div
          style={{
            height: "3em",
            marginBottom: 10,
            padding: 5,
            background: "#0000000A",
            borderRadius: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              ...(activeTab === Tab.INVITATION
                ? selectedButtonStyle
                : unselectedButtonStyle),
            }}
            onClick={() => handleChangeTab(Tab.INVITATION)}
          >
            {t("workspace.actions.invite")}
          </div>
          <div
            style={{
              ...(activeTab === Tab.BATCH_ADD
                ? selectedButtonStyle
                : unselectedButtonStyle),
            }}
            onClick={() => handleChangeTab(Tab.BATCH_ADD)}
          >
            {t("workspace.actions.batch_add")}
          </div>
        </div>
        {activeTab === Tab.INVITATION ? (
          <InvitationTab currentWorkspace={props.currentWorkspace} />
        ) : (
          <BatchAddTab />
        )}
      </Modal>
    );
  };

  const InvitationTab = ({
    currentWorkspace,
  }: {
    currentWorkspace: Workspace;
  }) => {
    const [form] = Form.useForm();
    const [inviteeOptions, setInviteeOptions] = useState<
      SelectProps["options"]
    >([]);
    const [originInviteeOptions, setOriginInviteeOptions] = useState<
      SelectProps["options"]
    >([]);
    const [open, setOpen] = useState(false);
    const [memberships, setMemberships] = useState([] as any[]);
    const [inputValue, setInputValue] = useState("");
    const roleMenus = [
      {
        key: Role.MEMBER,
        label: t("workspace.member"),
      },
      {
        key: Role.ADMIN,
        label: t("workspace.admin"),
      },
    ];
    const maxRecords = 100;

    useEffect(() => {
      if (!!batchMembershipModalItem?.workspace) {
        form.resetFields();
        getUserListApi().then((res) => {
          if (res.success) {
            const options = res.data.map((item: User) => {
              return {
                value: item.username,
                label:
                  viewUtils.prettifyUsername(item.name) +
                  "(" +
                  item.username +
                  ")",
                data: item,
                disabled:
                  batchMembershipModalItem.workspace?.memberships.find(
                    (membership) => {
                      return item.username === membership.user.username;
                    }
                  ) !== undefined,
              };
            });
            setInviteeOptions(options);
            setOriginInviteeOptions(options);
          }
        });
      }
      // eslint-disable-next-line
    }, [batchMembershipModalItem.workspace, form]);

    const handleAdd = () => {
      if (!inputValue) {
        return;
      }

      // check if inputValue already exists in workspace members
      const existsMemberships = currentWorkspace.memberships;
      const userAlreadyAdded = existsMemberships.some(
        (item) => item.user.username === inputValue
      );
      if (userAlreadyAdded) {
        message.error(t("workspace.tips.member_is_existed"));
        return;
      }

      if (memberships.length === maxRecords) {
        message.error(
          t("workspace.tips.invitation_length", { num: maxRecords })
        );
        return;
      }

      setMemberships([
        {
          user: { username: inputValue, name: inputValue, email: inputValue },
          role: Role.MEMBER,
        },
        ...memberships,
      ]);
      setInputValue(""); // 清空输入框
      form.resetFields();
    };
    const handleDelete = (membership: Membership) => {
      if (!inviteeOptions) {
        return;
      }
      setMemberships(
        memberships?.filter((item) => {
          return item.user.username !== membership.user.username;
        })
      );
      const selectedOption = inviteeOptions?.find((item) => {
        return item.value === membership.user.username;
      });
      if (!selectedOption) {
        return;
      }
      selectedOption.disabled = false;
      setInviteeOptions([...inviteeOptions]);
    };

    const handleInvite = () => {
      if (memberships.length === 0) {
        message.error(t("workspace.tips.no_user_add"));
        return;
      }
      const existedMemberships =
        batchMembershipModalItem?.workspace?.memberships.filter((item) => {
          return item.type !== "invitation";
        });
      props.updateWorkspace({
        workspace: {
          ...batchMembershipModalItem?.workspace,
          memberships: memberships.concat(
            existedMemberships ? existedMemberships : []
          ),
        },
        success: (res: any) => {
          message.success(
            t("common.result_status", {
              title: t("workspace.actions.invite"),
              status: t("common.success"),
            })
          );
          resetWorkspace();
          setBatchMembershipModalItem({});
        },
        fail: (res: any) => {
          if (JSON.stringify(res.message).includes("Cannot find")) {
            message.error(t("workspace.tips.workspace_not_found"));
          } else {
            message.error(
              t("common.result_status", {
                title: t("workspace.actions.invite"),
                status: t("common.fail"),
              })
            );
          }
        },
      });
    };

    const handleImageEnter = (membership: Membership) => {
      membership.hover = true;
      setMemberships([...memberships]);
    };

    const handleImageLeave = (membership: Membership) => {
      membership.hover = false;
      setMemberships([...memberships]);
    };

    const handleClickRoleMenu = (key: any, membership: Membership) => {
      membership.role = key;
      membership.role_open = false;
      setMemberships([...memberships]);
    };

    const handleRoleMenuChange = (open: boolean, membership: Membership) => {
      membership.role_open = open;
      setMemberships((data) =>
        data.map((item) =>
          item.user?.username === membership.user?.username ? membership : item
        )
      );
    };

    return (
      <div style={{ display: "flex", flexDirection: "column", height: "25em" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minHeight: 0,
          }}
        >
          <Form
            form={form}
            layout="vertical"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Item
              label={t("workspace.account")}
              name="username"
              style={{ marginBottom: 0 }}
              rules={[
                {
                  required: true,
                  message: t("common.required", {
                    title: t("workspace.account"),
                  }),
                },
                ...(!config.IsYiliSso
                  ? [
                      {
                        pattern: viewUtils.emailOrPhoneRegular(),
                        message: t("common.invalid", {
                          title: t("workspace.account"),
                        }),
                      },
                    ]
                  : []),
              ]}
            >
              <Compact style={{ width: "100%" }}>
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={handleAdd}
                />
                <Button type="primary" onClick={handleAdd}>
                  {t("common.add")}
                </Button>
              </Compact>
            </Form.Item>
          </Form>
          <div style={{ flex: 1, overflowY: "auto" }}>
            {memberships?.map((membership: Membership) => (
              <div
                key={membership.user.username}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "0.5em",
                  marginBottom: "0.5em",
                  marginRight: "0.5em",
                }}
              >
                <Typography.Text>
                  {`${viewUtils.prettifyUsername(membership.user.name)}`}
                </Typography.Text>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: roleMenus,
                      onClick: ({ key }) =>
                        handleClickRoleMenu(key, membership),
                    }}
                    onOpenChange={(open) =>
                      handleRoleMenuChange(open, membership)
                    }
                  >
                    <div style={{ display: "flex", cursor: "pointer" }}>
                      <div>
                        {membership.role === Role.ADMIN
                          ? t("workspace.admin")
                          : t("workspace.member")}
                      </div>
                      {membership.role_open ? (
                        <CaretUpOutlined
                          style={{ color: "rgba(110,129,130)" }}
                        />
                      ) : (
                        <CaretDownOutlined
                          style={{ color: "rgba(110,129,130)" }}
                        />
                      )}
                    </div>
                  </Dropdown>
                  <div
                    style={{
                      width: "1px",
                      height: "14px",
                      margin: "0px 10px",
                      background: "rgb(183,183,183)",
                    }}
                  />
                  <Image
                    onMouseEnter={() => handleImageEnter(membership)}
                    onMouseLeave={() => handleImageLeave(membership)}
                    src={membership.hover ? DeleteHover : DeleteNormal}
                    preview={false}
                    width={"14px"}
                    onClick={() => handleDelete(membership)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div style={{ display: "flex", marginTop: 12, justifyContent: "end" }}>
          <Button onClick={() => setBatchMembershipModalItem({})}>
            {t("common.cancel")}
          </Button>
          <Button
            type="primary"
            style={{ marginInlineStart: 8 }}
            onClick={handleInvite}
          >
            {t("workspace.actions.invite_button", { num: memberships.length })}
          </Button>
        </div>
      </div>
    );
  };

  const BatchAddTab = () => {
    enum Status {
      INIT,
      FILE_SELECTED,
      UPLOAD_ERROR,
      UPLOAD_SUCCESS,
    }
    const [status, setStatus] = useState<Status>(Status.INIT);
    const [file, setFile] = useState<RcFile>();
    const [uploadResult, setUploadResult] = useState({
      href: "",
      download: "",
      success: 0,
      failure: 0,
    });
    const maxSize = 10; //10M
    const maxRecords = 65536;
    const handleDownload = () => {
      downloadBatchAddUsersFileApi(
        batchMembershipModalItem?.workspace!.id,
        i18n.resolvedLanguage ? i18n.resolvedLanguage : "en"
      ).then((res: any) => {
        const href = window.URL.createObjectURL(res.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", res.headers["file-name"]);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    };

    const beforeUpload = (file: RcFile) => {
      const isValid =
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "text/csv";
      if (!isValid) {
        message.error(t("workspace.tips.is_not_excel", { name: file.name }));
        return Upload.LIST_IGNORE;
      }
      if (file.size / 1024 / 1024 > maxSize) {
        message.error(t("workspace.tips.max_excel_size", { size: maxSize }));
        return Upload.LIST_IGNORE;
      }
      setFile(file);
      setStatus(Status.FILE_SELECTED);
      return Upload.LIST_IGNORE;
    };

    const handleDelete = () => {
      setFile(undefined);
      setStatus(Status.INIT);
    };

    const handleInvite = () => {
      if (!file) {
        message.error(t("workspace.tips.no_upload_file"));
        return;
      }
      const formData = new FormData();
      formData.append("files", file);
      formData.append("max-records", maxRecords + "");
      formData.append(
        "locale",
        i18n.resolvedLanguage ? i18n.resolvedLanguage : "en"
      );
      uploadBatchAddUsersFileApi(
        batchMembershipModalItem?.workspace!.id,
        formData
      ).then((res) => {
        if (res.headers["error"]) {
          if (res.headers["error"].includes("Invalid excel")) {
            message.error(t("workspace.tips.invalid_excel"));
            return;
          }
        }
        setUploadResult({
          href: window.URL.createObjectURL(res.data),
          download: res.headers["file-name"],
          success: res.headers["success-num"],
          failure: res.headers["failure-num"],
        });
        if (res.headers["failure-num"] == 0) {
          setStatus(Status.UPLOAD_SUCCESS);
        } else {
          setStatus(Status.UPLOAD_ERROR);
        }
      });
    };

    if (status === Status.INIT) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", height: "25em" }}
        >
          <div style={{ flex: 1 }}>
            <Dragger accept=".xls,.xlsx,.csv" beforeUpload={beforeUpload}>
              <p className="ant-upload-drag-icon">
                <Image src={Add} preview={false} height={"32px"} />
              </p>
              <p className="ant-upload-text" style={{ fontSize: 14 }}>
                {t("workspace.upload_file_method")}
              </p>
              <p className="ant-upload-hint">
                {t("workspace.upload_file_format")}
              </p>
            </Dragger>
          </div>
          <div style={{ marginTop: "1em" }}>
            <Trans
              i18nKey="workspace.import_rule"
              values={{
                size: maxSize,
                num: maxRecords,
              }}
              components={{
                p: <p style={{ color: "rgba(0,0,0,0.65)" }} />,
                a: <a style={{ color: "#1677ff" }} onClick={handleDownload} />,
              }}
            />
          </div>
          <div
            style={{ display: "flex", marginTop: 12, justifyContent: "end" }}
          >
            <Button onClick={() => setBatchMembershipModalItem({})}>
              {t("common.cancel")}
            </Button>
          </div>
        </div>
      );
    } else if (status === Status.FILE_SELECTED) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", height: "25em" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              background: "rgba(0, 0, 0, 0.02)",
            }}
          >
            <Image src={Excel} preview={false} height={"32px"} />
            <p className="ant-upload-hint" style={{ marginTop: 16 }}>
              {file?.name}
            </p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Upload beforeUpload={beforeUpload}>
                <Button type="link">{t("workspace.actions.reupload")}</Button>
              </Upload>
              <Button type="link" onClick={handleDelete}>
                {t("common.delete")}
              </Button>
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <Trans
              i18nKey="workspace.import_rule"
              values={{
                size: maxSize,
                num: maxRecords,
              }}
              components={{
                p: <p />,
                a: <a style={{ color: "#1677ff" }} onClick={handleDownload} />,
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: 12,
              justifyContent: "end",
            }}
          >
            <Button onClick={() => setBatchMembershipModalItem({})}>
              {t("common.cancel")}
            </Button>
            <Button
              type="primary"
              style={{ marginInlineStart: 8 }}
              onClick={handleInvite}
            >
              {t("workspace.actions.invite")}
            </Button>
          </div>
        </div>
      );
    } else if (status === Status.UPLOAD_SUCCESS) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", height: "25em" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              background: "rgba(0, 0, 0, 0.02)",
            }}
          >
            <Image src={Success} preview={false} height={"32px"} />
            <div style={{ marginTop: 16 }}>
              <Trans
                i18nKey="workspace.import_result"
                values={{
                  success: uploadResult.success,
                  failure: uploadResult.failure,
                }}
                components={{
                  success: <span style={{ color: "green" }} />,
                  failure: <span style={{ color: "red" }} />,
                  a: <a style={{ display: "none" }} />,
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <Trans
              i18nKey="workspace.import_rule"
              values={{
                size: maxSize,
                num: maxRecords,
              }}
              components={{
                p: <p />,
                a: <a style={{ color: "#1677ff" }} onClick={handleDownload} />,
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: 12,
              justifyContent: "end",
            }}
          >
            <Upload beforeUpload={beforeUpload}>
              <Button type="default">
                {t("workspace.actions.continue_invite")}
              </Button>
            </Upload>
            <Button
              type="primary"
              style={{ marginInlineStart: 8 }}
              onClick={() => {
                resetWorkspace();
                setBatchMembershipModalItem({});
              }}
            >
              {t("common.finish")}
            </Button>
          </div>
        </div>
      );
    } else if (status === Status.UPLOAD_ERROR) {
      return (
        <div
          style={{ display: "flex", flexDirection: "column", height: "25em" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              background: "rgba(0, 0, 0, 0.02)",
            }}
          >
            <Image src={Failure} preview={false} height={"32px"} />
            <div style={{ marginTop: 16 }}>
              <Trans
                i18nKey="workspace.import_result"
                values={{
                  success: uploadResult.success,
                  failure: uploadResult.failure,
                }}
                components={{
                  success: <span style={{ color: "green" }} />,
                  failure: <span style={{ color: "red" }} />,
                  a: (
                    <a
                      style={{ color: "#1677ff" }}
                      href={uploadResult.href}
                      download={uploadResult.download}
                    />
                  ),
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <Trans
              i18nKey="workspace.import_rule"
              values={{
                size: maxSize,
                num: maxRecords,
              }}
              components={{
                p: <p />,
                a: <a style={{ color: "#1677ff" }} onClick={handleDownload} />,
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: 12,
              justifyContent: "end",
            }}
          >
            <Upload beforeUpload={beforeUpload}>
              <Button type="default">
                {t("workspace.actions.continue_invite")}
              </Button>
            </Upload>
            <Button
              type="primary"
              style={{ marginInlineStart: 8 }}
              onClick={() => {
                resetWorkspace();
                setBatchMembershipModalItem({});
              }}
            >
              {t("common.finish")}
            </Button>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const resetWorkspace = () => {
    if (!props.currentWorkspace) {
      return;
    }
    getWorkspaceApi({ workspaceId: props.currentWorkspace.id }).then((res) => {
      if (res.success) {
        const workspace: Workspace = res.data;

        workspace.memberships = workspace.memberships?.concat(
          workspace.invitations.map((item) => {
            return {
              ...item,
              user: {
                username: item.invitee_account,
                email: item.invitee_account,
                name: item.invitee_account,
              },
              type: "invitation",
            };
          })
        );
        workspace.memberships.sort((a, b) => {
          return (b.created_at || "").localeCompare(a.created_at || "");
        });
        setCurrentWorkspace(workspace);
      }
    });
  };

  const batchMembershipModal = useMemo(() => {
    return <BatchMembershipModal />;
    // eslint-disable-next-line
  }, [batchMembershipModalItem.workspace]);

  const MembershipModal = () => {
    const [form] = Form.useForm();

    useEffect(() => {
      if (!!membershipModalItem?.workspace) {
        form.resetFields();
        if (membershipModalItem?.membership) {
          form.setFieldsValue({
            email: membershipModalItem?.membership?.user.email,
            username: membershipModalItem?.membership?.user.username,
            name: membershipModalItem?.membership?.user.name,
            is_admin: membershipModalItem?.membership?.role === Role.ADMIN,
          });
        }
      }
      // eslint-disable-next-line
    }, [membershipModalItem, form]);

    const formSubmit = async () => {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      const data: {
        name?: string;
        is_admin?: boolean;
      } = form.getFieldsValue();
      if (!membershipModalItem?.workspace) {
        message.error(t("workspace.tips.no_workspace"));
        return;
      }
      if (!membershipModalItem?.membership) {
        message.error(t("workspace.tips.no_memeber"));
        return;
      }
      props.updateWorkspace({
        workspace: {
          ...membershipModalItem?.workspace,
          memberships: membershipModalItem?.workspace?.memberships?.map(
            (item: Membership) => {
              if (item.id !== membershipModalItem?.membership?.id) {
                return item;
              }
              if (data.name) {
                item.user.name = data.name;
              }
              item.role = data.is_admin === true ? Role.ADMIN : Role.MEMBER;
              return item;
            }
          ),
        },
        success: (res: any) => {
          message.success(
            t("common.result_status", {
              title: t(
                !!membershipModalItem?.membership
                  ? "common.update"
                  : "workspace.actions.invite"
              ),
              status: t("common.success"),
            })
          );
          resetWorkspace();
          setMembershipModalItem({});
        },
        fail: (res: any) => {
          if (JSON.stringify(res.message).includes("Cannot find")) {
            message.error(t("workspace.tips.workspace_not_found"));
          } else {
            message.error(
              t("common.result_status", {
                title: t(
                  !!membershipModalItem?.membership
                    ? "common.update"
                    : "workspace.actions.invite"
                ),
                status: t("common.fail"),
              })
            );
          }
        },
      });
    };

    if (!membershipModalItem?.workspace) {
      return null;
    }
    return (
      <Modal
        open={!!membershipModalItem.workspace}
        title={t(
          `workspace.actions.${
            !!membershipModalItem.membership
              ? "update_member"
              : "invite_memeber"
          }`
        )}
        okText={
          !!membershipModalItem.membership
            ? t("common.save")
            : t("workspace.actions.invite")
        }
        cancelText={t("common.cancel")}
        centered={true}
        onCancel={() => setMembershipModalItem({})}
        onOk={formSubmit}
      >
        <Form
          className="membership-edit"
          layout="vertical"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 28 }}
          form={form}
        >
          <Form.Item
            label={t("workspace.account")}
            name="username"
            className="form-item-row"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label={t("workspace.username")}
            name="name"
            className="form-item-row"
          >
            <Input
              disabled={
                "invitee_account" in (membershipModalItem.membership || {})
              }
            />
          </Form.Item>
          <Form.Item
            label={t("workspace.admin")}
            name="is_admin"
            valuePropName="checked"
            className="form-item-row"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const membershipModal = useMemo(() => {
    return <MembershipModal />;
    // eslint-disable-next-line
  }, [membershipModalItem]);

  const handleEdit = (membership: Membership) => {
    setMembershipModalItem({
      workspace: currentWorkspace,
      membership: membership,
    });
  };

  const handleAddMembership = () => {
    setBatchMembershipModalItem({
      workspace: currentWorkspace,
    });
  };

  const handleDeleteMember = (membership: Membership) => {
    if (!currentWorkspace) {
      return;
    }
    if ("user" in membership) {
      currentWorkspace.memberships.forEach((item) => {
        if (item.id === membership.id) {
          item.delete_flag = true;
        }
      });
    }
    if ("invitee_account" in membership) {
      currentWorkspace.invitations.forEach((item) => {
        if (item.id === membership.id) {
          item.delete_flag = true;
        }
      });
    }
    Modal.confirm({
      title: t("workspace.actions.delete_member"),
      icon: <ExclamationCircleOutlined />,
      content: t("workspace.tips.confirm_delete_member", {
        name:
          "invitee_account" in membership
            ? membership.invitee_account
            : membership.user.name,
      }),
      okText: t("common.ok"),
      cancelText: t("common.cancel"),
      centered: true,
      onOk: async () => {
        props.updateWorkspace({
          workspace: {
            ...currentWorkspace,
            memberships: currentWorkspace.memberships,
          },
          success: (res: any) => {
            message.success(
              t("common.result_status", {
                title: t("workspace.actions.delete_member"),
                status: t("common.success"),
              })
            );
            resetWorkspace();
          },
          fail: (res: any) => {
            if (JSON.stringify(res.message).includes("Cannot find")) {
              message.error(t("workspace.tips.workspace_not_found"));
            } else {
              message.error(
                t("common.result_status", {
                  title: t("workspace.actions.delete_member"),
                  status: t("common.fail"),
                })
              );
            }
          },
        });
      },
    });
  };

  return (
    <>
      {membershipModal}
      {batchMembershipModal}
      <div style={{ position: "relative", paddingTop: "1.5em" }}>
        <div className="tab-action" style={{ marginBottom: "1.5em" }}>
          {permission.isAdmin && (
            <Button
              type="primary"
              style={{ marginLeft: "1em" }}
              onClick={handleAddMembership}
            >
              {t("common.add_with_info", { title: t("workspace.member") })}
            </Button>
          )}
        </div>
        <Table
          columns={columns}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: [10, 20, 50],
            showTotal: (total) => t("common.total", { count: total }),
          }}
          dataSource={currentWorkspace?.memberships}
          scroll={{ x: 900, scrollToFirstRowOnChange: true }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (store: any) => ({
  user: store.account.user,
  currentWorkspace: store.account.currentWorkspace,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      updateWorkspace,
      resetWorkspaceList,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipManagePage);
