import { API, responseData } from "./BaseApi";
import type { Table, TableView } from "../types/Table";

export const getDatasetListApi = (data: {
  page?: number;
  page_size?: number;
  ordering?: string;
  query?: string;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  if (data.query) queryParams.append("q", data.query);
  if (data.ordering) queryParams.append("ordering", data.ordering);
  return API.get<responseData, responseData>(
    `datasets/?${queryParams.toString()}`
  );
};

export const getDatasetApi = (data: { datasetId: number }) => {
  return API.get<responseData, responseData>(`datasets/${data.datasetId}/`);
};

export const getDatasetExtraConfigApi = (data: { datasetId: number }) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/extra_config/`
  );
};

export const updateDatasetExtraConfigApi = (data: {
  datasetId: number;
  data: object;
}) => {
  return API.put<responseData, responseData>(
    `datasets/${data.datasetId}/extra_config/`,
    data.data
  );
};

export const updateDatasetItemsIndexApi = (data: {
  datasetId: number;
  items: { type: string; id: number; index: number }[];
}) => {
  return API.post<responseData, responseData>(
    `datasets/${data.datasetId}/update-items-index/`,
    {
      items: data.items,
    },
    {
      headers: { skipLoading: true },
    }
  );
};

export const getDatasetMembersApi = (data: { datasetId: number }) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/members/`
  );
};

export const updateDatasetMembersApi = (data: {
  datasetId: number;
  data: object;
}) => {
  return API.post<responseData, responseData>(
    `datasets/${data.datasetId}/update_members/`,
    data.data
  );
};

export const createDatasetApi = (data: {
  dataset: {
    name: string;
    description?: string;
    push_config?: object;
  };
  tables?: Table[];
  table_views?: TableView[];
}) => {
  return API.post<responseData, responseData>(
    `datasets/create_with_tables/`,
    data
  );
};

export const updateDatasetApi = (data: { datasetId: number; data: object }) => {
  return API.put<responseData, responseData>(
    `datasets/${data.datasetId}/`,
    data.data
  );
};

export const patchDatasetApi = (data: { datasetId: number; data: object }) => {
  return API.patch<responseData, responseData>(
    `datasets/${data.datasetId}/`,
    data.data
  );
};

export const deleteDatasetApi = (data: { datasetId: number }) => {
  return API.delete<responseData, responseData>(`datasets/${data.datasetId}/`);
};

export const getPushMethodsApi = () => {
  return API.get<responseData, responseData>(`pipeline/get-pipeline-list/`);
};

export const getFlowInstancesApi = (data: { datasetId: number }) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/flow/instance-list/`
  );
};

export const getDatasetTablesApi = (data: { datasetId: number }) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/tables/`
  );
};

export const getDatasetTableApi = (data: {
  datasetId: number;
  tableId: number;
}) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/table/${data.tableId}/`
  );
};

export const createDatasetTableApi = (data: {
  datasetId: number;
  data: object;
}) => {
  return API.post<responseData, responseData>(
    `datasets/${data.datasetId}/table/`,
    data.data
  );
};

export const updateDatasetTableApi = (data: {
  datasetId: number;
  tableId: number;
  data: object;
}) => {
  return API.put<responseData, responseData>(
    `datasets/${data.datasetId}/table/${data.tableId}/`,
    data.data
  );
};

export const deleteDatasetTableApi = (data: {
  datasetId: number;
  tableId: number;
}) => {
  return API.delete<responseData, responseData>(
    `datasets/${data.datasetId}/table/${data.tableId}/`
  );
};

export const getDatasetTableViewsApi = (data: { datasetId: number }) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/table-views/`
  );
};

export const getDatasetTableViewsDataApi = (data: { datasetId: number }) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/table-views/data/`
  );
};

export const getDatasetTableViewApi = (data: {
  datasetId: number;
  tableViewId: number;
}) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/table-view/${data.tableViewId}/`
  );
};

export const getDatasetTableViewDataApi = (data: {
  datasetId: number;
  tableViewId: number;
}) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/table-view/${data.tableViewId}/data/`
  );
};

export const createDatasetTableViewApi = (data: {
  datasetId: number;
  data: object;
}) => {
  return API.post<responseData, responseData>(
    `datasets/${data.datasetId}/table-view/`,
    data.data
  );
};

export const updateDatasetTableViewApi = (data: {
  datasetId: number;
  tableViewId: number;
  data: object;
}) => {
  return API.put<responseData, responseData>(
    `datasets/${data.datasetId}/table-view/${data.tableViewId}/`,
    data.data
  );
};

export const deleteDatasetTableViewApi = (data: {
  datasetId: number;
  tableViewId: number;
}) => {
  return API.delete<responseData, responseData>(
    `datasets/${data.datasetId}/table-view/${data.tableViewId}/`
  );
};

export const getDatasetVersionListApi = (data: {
  page?: number;
  page_size?: number;
  ordering?: string;
  query?: string;
  dataset?: number;
  is_locked?: boolean;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  if (data.ordering) queryParams.append("ordering", data.ordering);
  if (data.query) queryParams.append("q", data.query);
  if (data.dataset) queryParams.append("dataset", data.dataset.toString());
  if (data.is_locked !== undefined)
    queryParams.append("is_locked", data.is_locked.toString());

  return API.get<responseData, responseData>(
    `dataset-versions/?${queryParams.toString()}`
  );
};

export const getDatasetVersionApi = (data: { versionId: number }) => {
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/`
  );
};

export const createDatasetVersionApi = (data: object) => {
  return API.post<responseData, responseData>(`dataset-versions/`, data);
};

export const updateDatasetVersionApi = (data: {
  versionId: number;
  data: object;
}) => {
  return API.patch<responseData, responseData>(
    `dataset-versions/${data.versionId}/`,
    data.data
  );
};

export const deleteDatasetVersionApi = (data: { versionId: number }) => {
  return API.delete<responseData, responseData>(
    `dataset-versions/${data.versionId}/`
  );
};

export const getDatasetVersionTableInfoApi = (data: { versionId: number }) => {
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/table/info/`
  );
};

export const getDatasetVersionDataApi = (data: { versionId: number }) => {
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/data/`
  );
};

export const getDatasetVersionTableDataApi = (
  data: {
    versionId: number;
    tableId: number;
  },
  config?: any
) => {
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/table/${data.tableId}/data/`,
    { ...config }
  );
};

export const lockDatasetVersionApi = (data: { versionId: number }) => {
  return API.post<responseData, responseData>(
    `dataset-versions/${data.versionId}/lock/`
  );
};

export const unlockDatasetVersionApi = (data: { versionId: number }) => {
  return API.post<responseData, responseData>(
    `dataset-versions/${data.versionId}/unlock/`
  );
};

export const getDatasetVersionTaskListApi = (data: {
  page?: number;
  page_size?: number;
  query?: string;
  assignee?: string;
  version?: number;
  tableId?: number;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  if (data.query) queryParams.append("q", data.query);
  if (data.assignee)
    queryParams.append("assignee_username", `${data.assignee}`);
  if (data.version) queryParams.append("version", `${data.version}`);
  if (data.tableId) queryParams.append("table", `${data.tableId}`);

  return API.get<responseData, responseData>(
    `tasks/?${queryParams.toString()}`
  );
};

export const getDatasetVersionTaskApi = (data: { taskId: number }) => {
  return API.get<responseData, responseData>(`tasks/${data.taskId}/`);
};

export const createDatasetVersionTaskApi = (data: object) => {
  return API.post<responseData, responseData>(`tasks/`, data, {
    headers: { skipLoading: true },
  });
};

export const updateDatasetVersionTaskApi = (
  data: {
    taskId: number;
    data: object;
  },
  skipLoading = false
) => {
  return API.put<responseData, responseData>(
    `tasks/${data.taskId}/`,
    data.data,
    { headers: { skipLoading: skipLoading } }
  );
};

export const deleteDatasetVersionTaskApi = (data: { taskId: number }) => {
  return API.delete<responseData, responseData>(`tasks/${data.taskId}/`, {
    headers: { skipLoading: true },
  });
};

export const updateDatasetVersionTaskDataApi = (
  data: {
    taskId: number;
    data: {
      data: object;
      log?: object;
    };
  },
  skipLoading = false
) => {
  return API.put<responseData, responseData>(
    `tasks/${data.taskId}/data/`,
    data.data,
    { headers: { skipLoading: skipLoading } }
  );
};

export const executeApi = (data: { versionId: number }) => {
  return API.post<responseData, responseData>(
    `dataset-versions/${data.versionId}/execute/`
  );
};

export const getDatasetVersionTableViewsApi = (data: { versionId: number }) => {
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/table-views/`
  );
};

export const getDatasetVersionTableViewsDataApi = (data: {
  versionId: number;
  limit?: number;
}) => {
  const limit = data.limit || 10005;
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/table-views/data/`,
    {
      params: {
        limit,
      },
    }
  );
};

export const getDatasetVersionTableViewDataApi = (data: {
  versionId: number;
  tableViewId: number;
  limit?: number;
}) => {
  const limit = data.limit || 10005;
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/table-view/${data.tableViewId}/data/`,
    {
      params: {
        limit,
      },
    }
  );
};

export const exportDatasetVersionTableViewDataApi = (data: {
  versionId: number;
  tableViewId: number;
}) => {
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/table-view/${data.tableViewId}/export/`,
    {
      responseType: "blob",
    }
  );
};

export const exportDatasetVersionApi = (data: { versionId: number }) => {
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/export/`,
    {
      responseType: "blob",
    }
  );
};

export const checkDatasetWorkspacePermissionApi = (data: {
  datasetId: number;
}) => {
  return API.get<responseData, responseData>(
    `datasets/${data.datasetId}/check-workspace-permission/`
  );
};

export const getDatasetVersionChartViewsApi = (data: {
  versionId: number;
  page?: number;
  page_size?: number;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  return API.get<responseData, responseData>(
    `dataset-versions/${data.versionId}/chart-views/?${queryParams.toString()}`
  );
};

export const createDatasetVersionChartViewApi = (data: object) => {
  return API.post<responseData, responseData>(`charts/`, data);
};

export const updateDatasetVersionChartViewApi = (data: {
  chartId: number;
  data: object;
}) => {
  return API.put<responseData, responseData>(
    `charts/${data.chartId}/`,
    data.data
  );
};

export const deleteDatasetVersionChartViewApi = (data: { chartId: number }) => {
  return API.delete<responseData, responseData>(`charts/${data.chartId}/`);
};

export const getAllDatasetVersionChartViewsApi = (data: {
  page?: number;
  page_size?: number;
}) => {
  let queryParams = new URLSearchParams();

  if (data.page) queryParams.append("page", data.page.toString());
  if (data.page_size)
    queryParams.append("page_size", data.page_size.toString());
  return API.get<responseData, responseData>(
    `charts/?${queryParams.toString()}`
  );
};
