import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Table as TableType,
  TableField,
  TableType as TableTypeEnum,
} from "../types/Table";
import {
  Button,
  Form,
  Typography,
  Input,
  Space,
  Modal,
  Radio,
  Select,
  Checkbox,
  InputNumber,
  Tooltip,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import TableFieldsList from "./TableFieldsList";
import { getTableListApi } from "../api/TableApi";
import viewUtils from "../utils/viewUtils";

const TableFieldInfoModal = forwardRef(
  (
    props: {
      table_type: string;
      submitCallback: (data: TableField, closeModal: () => void) => void;
      referenceTables?: TableType[];
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [showItem, setShowItem] = useState<TableField | undefined>();
    const [tableFields, setTableFields] = useState<TableField[]>([]);
    const [selectedReferenceTable, setSelectedReferenceTable] = useState<
      TableType | null | undefined
    >();
    const [form] = Form.useForm();
    const autoFillIdentifier = useRef(true);
    const isPk = Form.useWatch("is_pk", form);

    useEffect(() => {
      form.resetFields();
      form.setFieldsValue({
        is_pk: false,
        is_unique: false,
        is_nullable: true,
        readonly: false,
        ...showItem,
      });
      if (!!showItem?.identifier) {
        autoFillIdentifier.current = false;
      } else {
        autoFillIdentifier.current = true;
      }
      if (!!showItem?.fk_to) {
        setSelectedReferenceTable(
          props?.referenceTables?.find(
            (table: TableType) => table.id === showItem?.fk_to
          )
        );
      }
    }, [showItem, form, props?.referenceTables]);

    useEffect(() => {
      form.setFieldValue("is_nullable", !isPk);
    }, [isPk]);

    useImperativeHandle(ref, () => ({
      show: (item: TableField, fields: TableField[]) => {
        setTableFields(fields);
        setShowItem(item);
      },
    }));

    const handleCancel = () => {
      setShowItem(undefined);
    };

    const handleChangeFkto = (id: number) => {
      const FkTable = (props.referenceTables || []).find(
        (item) => item.id === id
      );
      setSelectedReferenceTable(FkTable);
      form.setFieldsValue({
        fk_table_name: FkTable?.name,
        fk_field: null,
        fk_literal: null,
      });
    };

    const handleSubmit = async () => {
      try {
        await form.validateFields();
      } catch (e) {
        return;
      }
      let field: TableField = form.getFieldsValue();
      field = {
        ...field,
        name: field.name?.trim(),
      };
      props.submitCallback && props.submitCallback(field, handleCancel);
    };

    const checkValueSame = (key: string, value: string) => {
      return !tableFields.find(
        (item: TableField) =>
          item[key === "identifier" ? "identifier" : "name"] === value
      );
    };

    const handleValuesChange = (changedValues: any, allValues: any) => {
      try {
        if (Object.keys(changedValues).includes("name")) {
          if (!!autoFillIdentifier.current) {
            form.setFieldsValue({
              identifier: viewUtils.autoFormatIdentifier(
                changedValues?.name || ""
              ),
            });
          }
        }
        if (Object.keys(changedValues).includes("identifier")) {
          autoFillIdentifier.current = !changedValues?.identifier;
        }
      } catch (e) {}
    };

    return (
      <Modal
        centered
        title={
          showItem?.name
            ? t("table.field.edit_field")
            : t("table.field.add_field")
        }
        open={!!showItem}
        onCancel={handleCancel}
        onOk={handleSubmit}
        cancelText={t("common.cancel")}
        okText={t("common.ok")}
      >
        <Form
          labelCol={{ span: 6 }}
          form={form}
          style={{ margin: "2em 0" }}
          onValuesChange={handleValuesChange}
        >
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="index_id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="index" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label={t("table.field.name")}
            rules={[
              {
                required: true,
                message: t("common.required", { title: t("table.field.name") }),
              },
              {
                max: 50,
                message: t("common.char_len_limit", {
                  title: t("table.field.name"),
                  count: 50,
                }),
              },
              {
                pattern:
                  /^[\u4e00-\u9fa5a-zA-Z0-9\s.,;!?'"\\/:[\]{}()-_=+*&^%$#@<>~|\uFF08-\uFF09]*$/,
                message: t("table.field.name_format"),
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || checkValueSame("name", value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("table.field.name_existed"))
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="identifier"
            label={t("table.field.identifier")}
            tooltip={t("table.field.identifier_info")}
            rules={[
              {
                required: true,
                message: t("table.field.identifier_format"),
              },
              {
                max: 64,
                message: t("common.char_len_limit", {
                  title: t("table.field.name"),
                  count: 64,
                }),
              },
              {
                pattern: /^[a-z][a-z_$0-9]{0,63}$/,
                message: t("table.field.identifier_format"),
              },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || checkValueSame("identifier", value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("table.field.identifier_existed"))
                  );
                },
              }),
            ]}
          >
            <Input disabled={!!showItem?.id} />
          </Form.Item>
          {props.table_type === TableTypeEnum.COMMON && (
            <Form.Item
              name="readonly"
              valuePropName="checked"
              label={t("table.field.readonly")}
              tooltip={t("table.tips.field_readonly")}
            >
              <Checkbox disabled={!!showItem?.id} />
            </Form.Item>
          )}

          <Form.Item name="is_pk" label={t("table.field.is_pk")}>
            <Radio.Group disabled={!!showItem?.id}>
              <Radio value={true}>{t("common.yes")}</Radio>
              <Radio value={false}>{t("common.no")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="is_unique" label={t("table.field.is_unique")}>
            <Radio.Group disabled={!!showItem?.id}>
              <Radio value={true}>{t("common.yes")}</Radio>
              <Radio value={false}>{t("common.no")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="is_nullable" label={t("table.field.is_nullable")}>
            <Radio.Group disabled={isPk}>
              <Radio value={true}>{t("common.yes")}</Radio>
              <Radio value={false}>{t("common.no")}</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="type"
            label={t("table.field.type")}
            rules={[
              {
                required: true,
                message: t("common.required", { title: t("table.field.type") }),
              },
            ]}
          >
            <Select disabled={!!showItem?.id}>
              <Select.Option value="TEXT">
                {t("table.field.text")}
              </Select.Option>
              <Select.Option value="NUMBER">
                {t("table.field.number")}
              </Select.Option>
              <Select.Option value="DATE">
                {t("table.field.date")}
              </Select.Option>
              <Select.Option value="BOOLEAN">
                {t("table.field.boolean")}
              </Select.Option>
              {(props.referenceTables || []).length > 0 && (
                <Select.Option value="REFERENCE">
                  {t("table.field.reference")}
                </Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.type !== curValues?.type
            }
          >
            {() => {
              if (form.getFieldValue("type") !== "NUMBER") {
                return null;
              }
              return (
                <>
                  <Form.Item
                    name="validation_rules"
                    noStyle
                    rules={[
                      {
                        required: false,
                        message: t("table.field.rule"),
                      },
                    ]}
                  >
                    <Form.Item
                      name={["validation_rules", "minimum"]}
                      label={t("table.field.min")}
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: t("common.required", {
                            title: t("table.field.min"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber placeholder={t("table.field.min")} />
                    </Form.Item>
                    <Form.Item
                      name={["validation_rules", "maximum"]}
                      label={t("table.field.max")}
                      labelCol={{ span: 6 }}
                      rules={[
                        {
                          required: false,
                          message: t("common.required", {
                            title: t("table.field.max"),
                          }),
                        },
                      ]}
                    >
                      <InputNumber placeholder={t("table.field.max")} />
                    </Form.Item>
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) =>
              prevValues?.type !== curValues?.type ||
              prevValues?.fk_to !== curValues?.fk_to
            }
          >
            {() => {
              if (form.getFieldValue("type") !== "REFERENCE") {
                return <></>;
              }
              return (
                <>
                  <Form.Item name="fk_table_name" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="fk_to"
                    label={t("table.field.reference_table")}
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      disabled={!!showItem?.id}
                      showSearch={true}
                      showArrow={false}
                      onChange={handleChangeFkto}
                      options={(props.referenceTables || []).map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                    />
                  </Form.Item>
                  <Form.Item
                    name="fk_field"
                    label={t("table.field.reference_field")}
                    tooltip={t("table.field.only_unique")}
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      disabled={!!showItem?.id}
                      showSearch={true}
                      showArrow={false}
                      options={(selectedReferenceTable?.meta?.fields || [])
                        .filter((item) => !!item.is_pk || !!item.is_unique)
                        .map((item) => ({
                          label: item.name,
                          value: item.identifier,
                        }))}
                    />
                  </Form.Item>
                  <Form.Item
                    name="fk_literal"
                    label={t("table.field.reference_field_display")}
                    labelCol={{ span: 6 }}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                  >
                    <Select
                      disabled={!!showItem?.id}
                      showSearch={true}
                      showArrow={false}
                      options={(selectedReferenceTable?.meta?.fields || []).map(
                        (item) => ({
                          label: item.name,
                          value: item.identifier,
                        })
                      )}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

const TableForm = (props: {
  action_class?: string;
  initData?: TableType;
  submitCallback?: (data: TableType) => void;
  cancelCallback?: () => void;
  referenceTables?: TableType[];
  notEditableField?: boolean;
  notEditableIdentifier?: boolean;
  notEditableInfo?: boolean;
  disabled?: boolean;
  needPrimaryKey?: boolean;
  checkName?: {
    tableType?: string;
    existedTables?: TableType[];
  };
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const tableFieldModalRef: any = useRef();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...props.initData,
      diseditable: props.initData?.editable === false ? true : false,
      table_type: props.initData?.table_type || TableTypeEnum.PRIMARY,
      fields: props.initData?.meta?.fields || props.initData?.fields,
    });
  }, [props.initData, form]);

  const handleAddFiled = () => {
    tableFieldModalRef?.current &&
      tableFieldModalRef?.current.show({}, form.getFieldValue("fields") || []);
  };

  const handleDeleteField = (filed: TableField, index: number) => {
    const OriginFileds = form.getFieldValue("fields") || [];
    form.setFieldsValue({
      fields: OriginFileds.filter(
        (item: TableField, tindex: number) => tindex !== index
      ),
    });
  };

  const handleEditField = (filed: TableField, index: number) => {
    tableFieldModalRef?.current &&
      tableFieldModalRef?.current.show(
        {
          ...filed,
          index_id: index,
        },
        (form.getFieldValue("fields") || []).filter(
          (item: TableField, findex: number) => findex !== index
        )
      );
  };

  const handleCloneField = (filed: TableField) => {
    tableFieldModalRef?.current &&
      tableFieldModalRef?.current.show(
        {
          ...filed,
          id: undefined,
          name: undefined,
          identifier: undefined,
        },
        form.getFieldValue("fields") || []
      );
  };

  const handleTableFieldInfoSubmit = (
    data: TableField,
    closeModal: () => void
  ) => {
    const OriginFileds = form.getFieldValue("fields") || [];
    if (data?.index === undefined || data?.index === null) {
      data.index =
        Math.max(
          ...[
            -1,
            ...OriginFileds.filter(
              (f: TableField) => !!f.index || f.index === 0
            ).map((f: TableField) => f.index),
          ]
        ) + 1;
    }
    if (data?.index_id !== undefined && data?.index_id !== null) {
      form.setFieldsValue({
        fields: OriginFileds.map((item: TableField, tindex: number) =>
          data.index_id === tindex ? { ...data, index_id: undefined } : item
        ),
      });
    } else {
      form.setFieldsValue({
        fields: [...OriginFileds, { ...data, index_id: undefined }],
      });
    }
    closeModal();
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const data = form.getFieldsValue();

    props.submitCallback &&
      props.submitCallback({
        ...data,
        editable: !data.diseditable,
      } as TableType);
  };

  const handleCancel = () => {
    props.cancelCallback && props.cancelCallback();
  };

  const checkName = async (name: string) => {
    if (props.checkName?.tableType) {
      const res = await getTableListApi({
        tableType: props.checkName?.tableType,
        query: name,
      });
      if (res.success && res.data.length > 0) {
        if (
          res.data.find(
            (item: TableType) =>
              item.name === name && item.id !== props.initData?.id
          )
        ) {
          return false;
        }
      }
    }
    if (props.checkName?.existedTables) {
      if (
        props.checkName?.existedTables.find(
          (item: TableType) =>
            item.name === name &&
            (item.id !== props.initData?.id ||
              item.temp_id !== props.initData?.temp_id)
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const checkPrimaryKey = async (fields: TableField[]) => {
    if (
      !!props.needPrimaryKey &&
      !fields.find((item: TableField) => !!item.is_pk)
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <TableFieldInfoModal
        ref={tableFieldModalRef}
        referenceTables={props.referenceTables}
        submitCallback={handleTableFieldInfoSubmit}
        table_type={props.initData?.table_type || TableTypeEnum.PRIMARY}
      />
      <Form
        form={form}
        labelAlign="right"
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Typography.Title level={5} style={{ marginBottom: 16 }}>
          {t("table.info")}
        </Typography.Title>
        <Form.Item
          name="name"
          label={t("common.name")}
          style={{ maxWidth: 500 }}
          rules={[
            { required: true },
            {
              max: 50,
              message: t("common.char_len_limit", {
                title: t("common.name"),
                count: 50,
              }),
            },
            {
              pattern:
                /^[\u4e00-\u9fa5a-zA-Z0-9\s.,;!?'"\\/:[\]{}()-_=+*&^%$#@<>~|\uFF08-\uFF09]*$/,
              message: t("table.tips.name_format"),
            },
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (!value || (await checkName(value))) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("table.tips.name_existed")));
              },
            }),
          ]}
        >
          <Input disabled={props.notEditableInfo || props.disabled} />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("common.description")}
          style={{ maxWidth: 500 }}
          rules={[
            {
              max: 100,
              message: t("common.char_len_limit", {
                title: t("common.description"),
                count: 100,
              }),
            },
          ]}
        >
          <Input.TextArea
            rows={4}
            disabled={props.notEditableInfo || props.disabled}
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues?.table_type !== curValues?.table_type
          }
        >
          {() => {
            const table_type = (form.getFieldValue("table_type") ||
              []) as string;
            if (table_type !== "PRIMARY") {
              return <></>;
            }
            return (
              <>
                <Form.Item name="diseditable" valuePropName="checked" noStyle>
                  <Checkbox
                    disabled={
                      props.notEditableInfo ||
                      props.disabled ||
                      !!props.initData?.id
                    }
                  >
                    {t("table.tips.sync_data")}
                  </Checkbox>
                </Form.Item>
                <Tooltip
                  title={t("table.tips.sync_data_help")}
                  overlayClassName="description_tooltip"
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </>
            );
          }}
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={5} style={{ marginBottom: 16 }}>
            {t("table.field.title")}
          </Typography.Title>
          {!props.disabled && (
            <Button
              disabled={props.notEditableField}
              type="primary"
              onClick={handleAddFiled}
            >
              {t("common.add_with_info", { title: t("table.field.title") })}
            </Button>
          )}
        </div>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="identifier" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="table_type" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="fields"
          rules={[
            { required: true, message: t("common.required", { title: "" }) },
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                if (!value || (await checkPrimaryKey(value))) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("table.tips.pk_must")));
              },
            }),
          ]}
          className="hidden-item-with-rules"
        >
          <Input />
        </Form.Item>
        <Form.Item name="temp_id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues?.fields !== curValues?.fields
          }
        >
          {() => {
            const fields = (form.getFieldValue("fields") || []) as TableField[];
            const handleFieldsChange = (fields: TableField[]) => {
              form.setFieldValue("fields", fields);
            };
            return (
              <TableFieldsList
                fields={fields}
                referenceTables={props.referenceTables}
                onFieldsChange={handleFieldsChange}
                {...(!props.disabled
                  ? {
                      actions: (d: TableType, index: number) => (
                        <>
                          <Button
                            size="small"
                            type="link"
                            onClick={() => handleEditField(d, index)}
                          >
                            {t("common.edit")}
                          </Button>
                          <Button
                            size="small"
                            type="link"
                            onClick={() => handleCloneField(d)}
                          >
                            {t("common.copy")}
                          </Button>
                          {!props.initData?.id && (
                            <Button
                              size="small"
                              type="link"
                              onClick={() => handleDeleteField(d, index)}
                            >
                              {t("common.delete")}
                            </Button>
                          )}
                        </>
                      ),
                    }
                  : null)}
              />
            );
          }}
        </Form.Item>
        {!props.disabled && (
          <Space
            className={props.action_class}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button onClick={handleCancel}>{t("common.back")}</Button>
            <Button type="primary" htmlType="submit">
              {t("common.save")}
            </Button>
          </Space>
        )}
      </Form>
    </>
  );
};

export default TableForm;
