import React, { useState, useEffect, useRef } from "react";
import { getDatasetVersionTableDataApi } from "../../api/DatasetApi";
import { ChartView } from "../../types/ChartView";
import { SheetTable } from "../../types/Table";
import { filterData } from "../../utils/SheetFilterUtils";

import PieChart from "./PieChart";
import DonutChart from "./DonutChart";
import LineChart from "./LineChart";
import ColumnChart from "./ColumnChart";
import BarChart from "./BarChart";
import StackedColumnChart from "./StackedColumnChart";
import StackedBarChart from "./StackedBarChart";
import StackedLineChart from "./StackedLineChart";
import PercentageColumnChart from "./PercentageColumnChart";
import PercentageBarChart from "./PercentageBarChart";
import Heatmap from "./Heatmap";
import LocationRelationship from "./LocationRelationship";

type Props = {
  id: string;
  chart_view?: ChartView;
};

const ChartViewComponent = React.memo(
  (props: Props) => {
    const [currentTable, setCurrentTable] = useState<SheetTable | undefined>();
    const currentFiltersRef: any = useRef();

    useEffect(() => {
      const controller = new AbortController();
      if (
        props.chart_view &&
        props.chart_view?.version &&
        props.chart_view?.table &&
        currentTable?.id !== props.chart_view?.table
      ) {
        getDatasetVersionTableDataApi(
          {
            versionId: Number(props.chart_view?.version),
            tableId: Number(props.chart_view?.table),
          },
          { signal: controller.signal }
        )
          .then((res) => {
            if (res.success) {
              setCurrentTable({
                ...res.data,
                filtered_data: filterData(
                  res.data?.data || [],
                  props.chart_view?.filters
                ),
              });
            }
          })
          .catch((error) => console.log(error));
      } else if (
        !!currentTable &&
        JSON.stringify(props.chart_view?.filters) !==
          JSON.stringify(currentFiltersRef.current)
      ) {
        setCurrentTable((data) => ({
          ...data,
          filtered_data: filterData(
            data?.data || [],
            props.chart_view?.filters
          ),
        }));
      }
      currentFiltersRef.current = props.chart_view?.filters;
      return () => {
        controller.abort();
      };
    }, [props.chart_view, currentTable]);

    if (props.chart_view?.chart_type === "COLUMN_CHART") {
      return (
        <ColumnChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "STACKED_COLUMN_CHART") {
      return (
        <StackedColumnChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "PERCENTAGE_COLUMN_CHART") {
      return (
        <PercentageColumnChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "BAR_CHART") {
      return (
        <BarChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "STACKED_BAR_CHART") {
      return (
        <StackedBarChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "PERCENTAGE_BAR_CHART") {
      return (
        <PercentageBarChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "PIE_CHART") {
      return (
        <PieChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "DONUT_CHART") {
      return (
        <DonutChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "LINE_CHART") {
      return (
        <LineChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "STACKED_LINE_CHART") {
      return (
        <StackedLineChart
          id={props.id}
          chart_view={props.chart_view}
          table={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "GAODE_HEATMAP") {
      return (
        <Heatmap
          id={props.id}
          chart_view={props.chart_view}
          table_data={currentTable}
        />
      );
    }

    if (props.chart_view?.chart_type === "GAODE_LOCATION_RELATIONSHIP") {
      return (
        <LocationRelationship
          id={props.id}
          chart_view={props.chart_view}
          table_data={currentTable}
        />
      );
    }

    return null;
  },
  (prev, next) => {
    return (
      prev.id === next.id &&
      JSON.stringify(prev.chart_view) === JSON.stringify(next.chart_view)
    );
  }
);

export default ChartViewComponent;
