import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DatasetVersion } from "../../types/Dataset";
import { Table } from "../../types/Table";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  Checkbox,
  Form,
  Modal,
  Button,
  Select,
  Typography,
  Row,
  Col,
} from "antd";
import { useNavigate } from "react-router-dom";
import IconCompare from "../../common/images/icons/compare";

const DatasetVersionCompare = (props: {
  versionList?: DatasetVersion[];
  currentVersion?: DatasetVersion;
  tables?: Table[];
}) => {
  const { t } = useTranslation();
  const IsSimpleLayout = window?.location?.pathname.startsWith("/simple");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (show) {
      form.resetFields();
      form.setFieldValue("origin_version", props.currentVersion?.id);
      setIndeterminate(false);
      setCheckAll(false);
    }
  }, [show, form, props.currentVersion]);

  const closeModal = () => {
    setShow(false);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    form.setFieldValue(
      "tables",
      e.target.checked
        ? (props.tables || []).map((table: Table) => table.id)
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onTableChange = (list: any[]) => {
    setIndeterminate(
      !!list.length && list.length < (props.tables || []).length
    );
    setCheckAll(list.length >= (props.tables || []).length);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    const data = form.getFieldsValue();
    const TableSort = (props.tables || []).map((t) => t.id || 0);
    navigate(
      `${IsSimpleLayout ? "/simple" : ""}/dataset/${
        props.currentVersion?.dataset
      }/compare/${`${data.origin_version}-${data.version}`}/${(
        data.tables || []
      )
        .sort((a: any, b: any) => TableSort.indexOf(a) - TableSort.indexOf(b))
        .join("-")}`
    );
  };

  return (
    <>
      <Modal
        centered
        width={550}
        forceRender={true}
        title={t("dataset.actions.compare")}
        open={!!show}
        onCancel={closeModal}
        onOk={handleSubmit}
        cancelText={t("common.cancel")}
        okText={t("common.ok")}
      >
        <Form form={form} style={{ margin: "2em 0" }}>
          <Typography.Paragraph>
            {t("dataset.tips.select_version")}
          </Typography.Paragraph>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            <Form.Item
              name="origin_version"
              style={{ width: "calc(50% - 2em)" }}
              rules={[
                {
                  required: true,
                  message: t("dataset.tips.select_origin_version"),
                },
              ]}
            >
              <Select
                allowClear={true}
                placeholder={t("dataset.tips.select_origin_version")}
                style={{ width: "100%" }}
                options={(props.versionList || []).map((item) => ({
                  label: `${item.name}${
                    item.is_locked ? `-${t("dataset.task_status.locked")}` : ""
                  }`,
                  value: item.id,
                }))}
              />
            </Form.Item>
            <Typography.Text
              style={{ flex: "0 0 auto", margin: "0 1em 24px 1em" }}
            >
              VS
            </Typography.Text>
            <Form.Item
              name="version"
              style={{ width: "calc(50% - 2em)" }}
              rules={[
                {
                  required: true,
                  message: t("common.required", { title: "" }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("origin_version") !== value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("dataset.tips.select_diff_version"))
                    );
                  },
                }),
              ]}
            >
              <Select
                allowClear={true}
                placeholder={t("dataset.tips.select_new_version")}
                style={{ width: "100%" }}
                options={(props.versionList || []).map((item) => ({
                  label: `${item.name}${
                    item.is_locked ? `-${t("dataset.task_status.locked")}` : ""
                  }`,
                  value: item.id,
                }))}
              />
            </Form.Item>
          </div>
          <Typography.Paragraph>
            {t("dataset.file_compare")}
            <Checkbox
              style={{ marginLeft: "2em" }}
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            >
              {t("common.check_all")}
            </Checkbox>
          </Typography.Paragraph>
          <Form.Item
            style={{ width: "100%" }}
            name="tables"
            rules={[
              { required: true, message: t("common.required", { title: "" }) },
            ]}
          >
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onTableChange}
              className="compare-checkbox"
            >
              <Row gutter={[16, 10]} style={{ width: "100%" }}>
                {(props.tables || []).map((table: Table) => (
                  <Col key={table.id} xs={12} sm={8}>
                    <Checkbox value={table.id}>
                      <Typography.Text ellipsis={{ tooltip: table.name }}>
                        {table.name}
                      </Typography.Text>
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      </Modal>
      {!!props.versionList && props.versionList.length > 1 && (
        <div className="action-item">
          <Button
            icon={
              <IconCompare
                style={{ fontSize: "1.4em", marginBottom: "0.1em" }}
              />
            }
            onClick={() => setShow(true)}
          >
            {t("dataset.actions.compare")}
          </Button>
        </div>
      )}
    </>
  );
};

export default DatasetVersionCompare;
