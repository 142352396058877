import moment from "moment";
import { pinyin } from "pinyin-pro";
import type { Dataset, DatasetMembership } from "../types/Dataset";
import type { TableField } from "../types/Table";

class viewUtils {
  static prettifyTextWithLocale(
    data?: {
      en?: string;
      zh?: string;
    },
    locale?: string
  ) {
    return (
      (data || {})[locale === "zh" ? "zh" : "en"] ||
      (data || {})[locale === "zh" ? "en" : "zh"] ||
      ""
    );
  }

  static autoFormatIdentifier(string: string) {
    return pinyin(string, {
      toneType: "none",
      type: "array",
    })
      .join("")
      .replaceAll(/ /gi, "_")
      .toLowerCase()
      .replaceAll(/[^a-z_$0-9]/gi, "")
      .replace(/^[^a-z]+/i, "");
  }

  static prettifyDatatime(date: any) {
    const fmt = "YYYY-MM-DD HH:mm:ss";
    return moment(date).local().format(fmt);
  }

  static emailOrPhoneRegular() {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))|(\d{11})$/g;
  }

  static emailRegular() {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
  }

  static prettifyErrorMessage(message: any) {
    if (!message) {
      return "";
    }
    if (message instanceof Array && typeof message[0] === "string") {
      return `${message}`;
    }
    if (message instanceof Object) {
      return "";
    }
    if (message instanceof Array && typeof message[0] !== "string") {
      return "";
    }
    return `${message}`;
  }

  static prettifyUsername(name?: string) {
    if (!!name && /^[\u4E00-\u9FA5]+$/g.test(name.replace(" ", ""))) {
      return name.split(" ")?.reverse()?.join("") || "";
    } else {
      return name || "";
    }
  }

  static isDatasetAdmin(dataset?: Dataset, user?: string) {
    return (
      !!dataset &&
      !!user &&
      (user === dataset.owner ||
        (dataset?.members || [])
          .filter((u: DatasetMembership) => u.role === "ADMIN")
          .map((u: DatasetMembership) => u.user)
          .includes(user))
    );
  }

  static canDatasetVisit(dataset?: Dataset, user?: string) {
    return (
      !!dataset &&
      !!user &&
      (user === dataset.owner ||
        (dataset?.members || [])
          .map((u: DatasetMembership) => u.user)
          .includes(user))
    );
  }

  static utcToLocal(date: any) {
    const fmt = "YYYY-MM-DD HH:mm:ss";
    return date ? moment.utc(date).local().format(fmt) : "";
  }

  static sortTableFields(fields?: TableField[]) {
    return (
      fields?.sort((a, b) => {
        const ret =
          (a.index !== null && a.index !== undefined ? a.index : 99999) -
          (b.index !== null && b.index !== undefined ? b.index : 99999);
        if (ret === 0 && !!a.id && !!b.id) {
          return a.id - b.id;
        }
        return ret;
      }) || []
    );
  }

  static decimalFormat(value: any, accuracy: number) {
    var f = parseFloat(value);
    if (isNaN(f)) {
      return value;
    }
    const accuracyNumber =
      accuracy > 0
        ? parseInt(`1${new Array(accuracy - 1).fill("0").join("")}`)
        : 1;
    f = Math.round(value * accuracyNumber) / accuracyNumber;
    var s = f.toFixed(accuracy);
    return s;
  }

  static showDiffValue(value: any, originValue: any) {
    if (
      typeof value === "number" &&
      typeof originValue === "number" &&
      value !== originValue
    ) {
      const diff =
        Math.floor((value - originValue) * Math.pow(10, 10)) / Math.pow(10, 10);
      return (
        <span
          style={{
            color: diff < 0 ? "green" : "red",
            marginLeft: "0.3em",
          }}
        >
          ({diff})
        </span>
      );
    } else {
      return <></>;
    }
  }

  static showDiffRatio(value: any, originValue: any) {
    if (
      typeof value === "number" &&
      typeof originValue === "number" &&
      value !== originValue
    ) {
      const diff =
        Math.floor((value - originValue) * Math.pow(10, 10)) / Math.pow(10, 10);
      const diff_ratio =
        originValue === 0 ? 0 : Math.floor(((diff * 1.0) / originValue) * 100);
      return (
        <span
          style={{
            color: diff < 0 ? "green" : "red",
            marginLeft: "0.3em",
          }}
        >
          ({diff_ratio}%{diff_ratio > 0 ? "↑" : diff_ratio < 0 ? "↓" : ""})
        </span>
      );
    } else {
      return <></>;
    }
  }
}

export default viewUtils;
