import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NavHyperLink from "../../components/NavHyperLink";
import ApiKeyManagePage from "./ApiKeyManagePage";
import MembershipManagePage from "./MembershipManagePage";
import { Tabs } from "antd";

const WorkspaceManagePage = () => {
  const { t } = useTranslation();

  return (
    <>
      <NavHyperLink items={[{ title: t("workspace.title") }]} />
      <div className="layout-content-box" style={{ paddingTop: "1em" }}>
        <Tabs
          className="workspace-manage-page"
          destroyInactiveTabPane={true}
          items={[
            {
              label: t("workspace.actions.membership_management"),
              key: "membership_management",
              children: <MembershipManagePage />,
            },
            {
              label: t("workspace.actions.auth_management"),
              key: "auth_management",
              children: <ApiKeyManagePage />,
            },
          ]}
        />
      </div>
    </>
  );
};

export default WorkspaceManagePage;
