import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePermission from "../../utils/usePermission";
import { useParams } from "react-router-dom";
import viewUtils from "../../utils/viewUtils";
import { showWorkspacePermissionModal } from "../../components/WorkspacePermissionModal";
import { Grid } from "antd";
import { SheetTable, TableField, TableView } from "../../types/Table";
import {
  Dataset,
  DatasetItem,
  DatasetVersion,
  Task,
} from "../../types/Dataset";
import {
  checkDatasetWorkspacePermissionApi,
  getDatasetApi,
  getDatasetVersionDataApi,
  getDatasetVersionListApi,
  getDatasetVersionTableViewsApi,
  getDatasetVersionTableInfoApi,
} from "../../api/DatasetApi";

import { getConfig } from "../../config/config";
const config = getConfig();

const useDatasetStore = () => {
  const { t } = useTranslation();
  const permission = usePermission();
  const screens = Grid.useBreakpoint();
  const { dataset_id } = useParams();

  const [currentDataset, setCurrentDataset] = useState<Dataset | undefined>();
  const [currentVersion, setCurrentVersion] = useState<
    DatasetVersion | undefined
  >();
  const [versionList, setVersionList] = useState<
    DatasetVersion[] | undefined
  >();
  const [versionTables, setVersionTables] = useState<
    SheetTable[] | undefined
  >();

  const enableUseForm =
    !!config.enableMobileFormMode && !screens?.lg && !currentDataset?.can_visit;
  const shouldUseForm =
    !!window?.location?.href?.includes("mode=form") && enableUseForm;

  //init dataset info
  useEffect(() => {
    if (!!permission.username) {
      getDatasetApi({ datasetId: Number(dataset_id) }).then((res) => {
        if (res.success) {
          setCurrentDataset({
            ...res.data,
            is_owner: permission?.username === res.data?.owner,
            is_admin: viewUtils.isDatasetAdmin(res.data, permission?.username),
            can_visit: viewUtils.canDatasetVisit(
              res.data,
              permission?.username
            ),
          });
        } else {
          setCurrentDataset({});
          if (res.code === 404) {
            checkDatasetWorkspacePermissionApi({
              datasetId: Number(dataset_id),
            }).then((res) => {
              if (res.success) {
                if (
                  res.data.has_permission === res.data.has_workspace_permission
                ) {
                  showWorkspacePermissionModal({
                    hasPermission:
                      res.data.has_permission &&
                      res.data.has_workspace_permission,
                    targetWorkspaceId: res.data.workspace_id,
                  });
                }
              }
            });
          }
        }
      });
    }
  }, [permission, dataset_id]);

  //init version list
  useEffect(() => {
    if (!!currentDataset?.id) {
      getDatasetVersionListApi({
        dataset: currentDataset?.id,
        page_size: 1000,
        ordering: "-created_at",
      }).then((res) => {
        if (res?.success) {
          setVersionList(res.data);
        }
      });
    }
  }, [currentDataset]);

  //init current version
  useEffect(() => {
    if (
      versionList &&
      versionList.length > 0 &&
      !currentVersion?.id &&
      !!currentDataset
    ) {
      setCurrentVersion(versionList[0]);
    }
  }, [versionList, currentVersion, currentDataset]);

  //init version tables
  useEffect(() => {
    if (shouldUseForm) {
      refreshVersionSheetFormData();
    } else {
      initVersionTables();
    }
    // eslint-disable-next-line
  }, [currentVersion, currentDataset, shouldUseForm]);

  const formatTableData = (table: SheetTable) => {
    let tasks = table?.tasks || [];
    if (!currentDataset?.is_admin) {
      tasks = tasks.filter(
        (task: Task) => task.assignee === permission.username
      );
    }
    try {
      return {
        id: table.id,
        identifier: table?.identifier,
        name: table?.name,
        meta: table?.meta,
        table_type: table?.table_type,
        tasks: tasks,
        editable:
          tasks?.length === 1 &&
          !tasks[0].is_locked &&
          (currentDataset?.is_admin ||
            tasks[0].assignee === permission.username),
        is_admin: !!currentDataset?.is_admin,
      };
    } catch (e) {
      return null;
    }
  };

  const formatTableViewData = (tableView: TableView) => {
    try {
      return {
        id: tableView.id,
        identifier: tableView.parent_table_data?.identifier,
        name: `【${t("table.title")}】${tableView.parent_table_data?.name}`,
        origin_table_name: tableView.parent_table_data?.name,
        meta: {
          fields: tableView.parent_table_data?.meta?.fields?.filter(
            (field: TableField) =>
              tableView?.columns?.includes(field.identifier || "")
          ),
        },
        table_type: tableView.parent_table_data?.table_type,
      };
    } catch (e) {
      return null;
    }
  };

  const refreshVersionSheetFormData = () => {
    if (!!currentDataset?.id && !!currentVersion?.id) {
      getDatasetVersionDataApi({
        versionId: Number(currentVersion.id),
      }).then((res) => {
        if (res.success) {
          let tableData = res.data.filter((table: SheetTable) =>
            table.tasks?.find(
              (task: Task) => task?.assignee === permission?.username
            )
          );
          if (!!currentDataset?.items?.length) {
            // sort tableData by currentVersion.items
            const sortedTableData: SheetTable[] = [];
            currentDataset?.items
              ?.sort((item1: DatasetItem, item2: DatasetItem) => {
                return item1.index! - item2.index!;
              })
              .forEach((item: DatasetItem) => {
                if (item.content_type === "table") {
                  const table = tableData.find(
                    (table: SheetTable) =>
                      table.id === item.object_id &&
                      table.table_type === "COMMON"
                  );
                  if (!!table) {
                    sortedTableData.push(table);
                  }
                }
              });
            tableData = sortedTableData;
          }
          setVersionTables(
            tableData.map((item: SheetTable, index: number) => {
              item.current_task =
                item?.tasks?.length === 1 ? item?.tasks[0] : undefined;
              item.active = index === 0;
              return item;
            })
          );
        }
      });
    }
  };

  const initVersionTables = () => {
    if (!!currentDataset?.id && !!currentVersion?.id && !versionTables) {
      let tableData: SheetTable[];
      getDatasetVersionTableInfoApi({
        versionId: Number(currentVersion.id),
      })
        .then((res) => {
          if (res.success) {
            tableData = currentDataset?.can_visit
              ? res.data
              : res.data.filter((table: SheetTable) =>
                  table.tasks?.find(
                    (task: Task) => task?.assignee === permission?.username
                  )
                );
            return getDatasetVersionTableViewsApi({
              versionId: Number(currentVersion.id),
            });
          }
        })
        .then((res) => {
          if (!!tableData) {
            tableData = [
              ...tableData.map(formatTableData),
              ...(res?.data || []).map(formatTableViewData),
            ];
            if (!!currentDataset?.items?.length) {
              // sort tableData by currentVersion.items
              const sortedTableData: SheetTable[] = [];
              currentDataset?.items
                ?.sort((item1: DatasetItem, item2: DatasetItem) => {
                  return item1.index! - item2.index!;
                })
                .forEach((item: DatasetItem) => {
                  if (item.content_type === "table") {
                    const table = tableData.find(
                      (table: SheetTable) =>
                        table.id === item.object_id &&
                        table.table_type === "COMMON"
                    );
                    if (!!table) {
                      sortedTableData.push(table);
                    }
                  } else if (item.content_type === "tableview") {
                    const table = tableData.find(
                      (table: SheetTable) =>
                        table.id === item.object_id &&
                        table.table_type === "PRIMARY"
                    );
                    if (!!table) {
                      sortedTableData.push(table);
                    }
                  }
                });
              tableData = sortedTableData;
            }
            setVersionTables(
              tableData.map((item: SheetTable, index: number) => {
                item.current_task =
                  item?.tasks?.length === 1 ? item?.tasks[0] : undefined;
                item.active = index === 0;
                return item;
              })
            );
          }
        });
    }
  };

  const updateDataset = (dataset: Dataset) => {
    if (!!dataset.id) {
      setCurrentDataset((data) => ({
        ...data,
        ...dataset,
      }));
    }
  };

  const updateVersion = (version: DatasetVersion) => {
    setVersionList((list) => {
      if (!!list?.find((item) => item.id === version?.id)) {
        return list.map((item) => (item.id === version?.id ? version : item));
      } else {
        return [version, ...(list || [])];
      }
    });
    if (currentVersion?.id !== version.id) {
      setVersionTables(undefined);
    }
    setCurrentVersion(version);
  };

  const deleteVersion = (version: DatasetVersion) => {
    const currentList = versionList?.filter((item) => item.id !== version.id);
    setVersionList(currentList);
    if (currentVersion?.id === version.id) {
      setVersionTables(undefined);
      setCurrentVersion((currentList || [])[0]);
    }
  };

  const changeCurrentVersion = (id: number) => {
    const version = versionList?.find((item: DatasetVersion) => item.id === id);
    if (!!version) {
      setVersionTables(undefined);
      setCurrentVersion(version);
    }
  };

  const changeTable = (table: SheetTable) => {
    setVersionTables((tables) => {
      return tables?.map((t) => ({
        ...t,
        active: t.identifier === table.identifier,
      }));
    });
  };

  const changeTask = (id: any) => {
    setVersionTables((tables) => {
      return tables?.map((t) => ({
        ...t,
        current_task: !!t.active
          ? id === "all"
            ? null
            : t.tasks?.find((task: Task) => task.id === id)
          : t.current_task,
      }));
    });
  };

  const changeCurrentTasks = (
    action: String,
    task: Task,
    table_id?: number
  ) => {
    let table = table_id
      ? versionTables?.find((table: SheetTable) => table.id === table_id)
      : versionTables?.find((table: SheetTable) => !!table.active);
    if (!!table) {
      if (action === "add") {
        if (table?.tasks?.length === 0) {
          table.current_task = task;
          table.editable = true;
        }
        table.tasks = [...table.tasks, task];
      } else if (action === "delete") {
        if (table?.tasks?.length === 1) {
          table.current_task = undefined;
          table.editable = false;
        }
        table.tasks = table.tasks?.filter((t: Task) => t.id !== task.id) || [];
      } else if (action === "update") {
        table.current_task = task;
        table.tasks =
          table.tasks?.map((t: Task) => (t.id === task.id ? task : t)) || [];
      }
      setVersionTables((tables) => {
        return tables?.map((t) =>
          !!table && t.identifier === table.identifier ? table : t
        );
      });
      return table;
    } else {
      return null;
    }
  };

  const updateCurrentTaskStatus = (
    locked: boolean,
    tableIdentifier?: string,
    taskId?: number
  ) => {
    let table = !!tableIdentifier
      ? versionTables?.find(
          (table: SheetTable) => table.identifier === tableIdentifier
        )
      : versionTables?.find((table: SheetTable) => !!table.active);
    if (!!table && !!table.current_task) {
      if (!taskId || taskId === table.current_task?.id) {
        table.current_task.is_locked = locked;
      }
      if (!!taskId) {
        table.tasks = table.tasks.map((task: Task) =>
          task.id === taskId
            ? {
                ...task,
                is_locked: locked,
              }
            : task
        );
      } else {
        table.tasks = table.tasks.map((task: Task) =>
          task.id === table?.current_task?.id ? table?.current_task : task
        );
      }
      setVersionTables((tables) => {
        return tables?.map((t) =>
          !!table && t.identifier === table.identifier ? table : t
        );
      });
    }
  };

  const updateCurrentVersionStatus = (locked: boolean) => {
    let version = {
      ...currentVersion,
      is_locked: locked,
    };
    setCurrentVersion(version);
    setVersionList((data) =>
      data?.map((v: DatasetVersion) =>
        v.id === currentVersion?.id ? version : v
      )
    );
  };

  return {
    permission,
    dataset_id,
    currentDataset,
    currentVersion,
    versionList,
    versionTables,
    enableUseForm,
    shouldUseForm,
    updateVersion,
    deleteVersion,
    updateDataset,
    changeCurrentVersion,
    changeTable,
    changeTask,
    changeCurrentTasks,
    updateCurrentTaskStatus,
    updateCurrentVersionStatus,
    setVersionTables,
    refreshVersionSheetFormData,
  };
};

export default useDatasetStore;
